<template>
    <div style="width: 100%;height: 100%;margin-top: 10px;">
        <!--  精选案例-->
        <div style="width: 100%;height: auto;">
            <div style="width: 100%;height: auto;margin-top: 5px;">
                <div style="float: left;width: 200px;height: 80px;">
                    <div style="float: left;font-weight: 400;font-size: 22px;line-height: 80px;padding-left: 40px;">精选案例</div>
                    <div style="float: left;line-height: 80px;margin-top: 6px;margin-left: 5px;font-weight: bold;">
                        <svg t="1653042634751" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6547" width="22" height="22"><path d="M448 736c-8.19 0-16.38-3.12-22.62-9.38-12.5-12.5-12.5-32.75 0-45.25L594.75 512 425.38 342.62c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l180.69 180.69c18.72 18.72 18.72 49.16 0 67.88l-180.7 180.68c-6.24 6.26-14.43 9.38-22.62 9.38z m158.06-212.69h0.31-0.31z" p-id="6548" fill="#FF9800"></path><path d="M512 128c211.74 0 384 172.26 384 384S723.74 896 512 896 128 723.74 128 512s172.26-384 384-384m0-64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z" p-id="6549" fill="#FF9800"></path></svg>
                    </div>
                </div>
                <div style="float: left;width: 50%;height: 80px;background: #aa7700;">
                    <div id='root2' class="root">
                        <ul id="list2" style="display: inline-block;margin-top: 30px;margin-left: 10px;" v-for="(item,index) in TypeList" :key="index">
                            <li @click="shopscreen(item.value)" :class="{on: item.value == type}" style="float: left;padding: 0px 30px;cursor: pointer;font-size: 18px;overflow: hidden" >{{item.label}}</li>
                        </ul>
                    </div>
                </div>
                <!-- 精选案例第一行 -->
                <div v-if="ShopCase.length > 0" style="width: 1500px;height: auto;margin-left: 40px;float: left">
                    <div v-for="(item,index) in ShopCase" :key="index" style="width: auto;float: left">
                        <div style="width: 355px;height: 490px;border: 1px solid #DCDCDC;margin-bottom: 10px;">
                            <div style="width: 319px;height: 226px;margin: 14px 0 0 18px;">
                                <img style="width: 100%;height: 100%;border-radius: 5px;object-fit: cover;" :src="item.img">
                            </div>
                            <div style="width: auto;height: 24px;color: #444444;font-size: 18px;margin: 28px 0 0 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{item.title}}</div>
                            <div style="width: 90px;border: 2px solid #BCD2FF;height: 27px;margin: 12px 0 0 20px;float: left">
                                <div style="width: auto;height: 19px;font-size: 16px;color: #2970FF;margin: 3px 0 0 10px">品牌策划</div>
                            </div>
                            <div style="width: 90px;border: 2px solid #BCD2FF;height: 27px;margin: 12px 0 0 20px;float: left">
                                <div style="width: auto;height: 19px;font-size: 16px;color: #2970FF;margin: 3px 0 0 5px">IP形象设计</div>
                            </div>
                            <div style="width: 50px;height: 50px;margin: 14px 0 0 20px;">
                                <img width="100%;" height="100%" style="border-radius: 50%;float: left;margin-top: 15px;" src="https://oss.baigongbao.com/2021/12/03/20211203_104657_185181.png">
                            </div>
                            <div style="margin: 3px 0 0 8px;width: 160px;height: 18px;font-size: 16px;color: #666666;float: left">新土地城乡规划设计院</div>
                            <div style="width: 200px;float: left"></div>
                            <div style="width: 18px;height: 18px;background: #2970FF;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 10px;">
                                <div style="width: 18px;height: 20px;color: #FFFFFF;margin: 1px 0 0 3px;">企</div>
                            </div>
                            <div style="width: 58px;height: 18px;background: #FF9800;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 6px">
                                <div style="height: 14px;font-size: 12px;color: #FFFFFF;margin: 1px 0 0 4px">10年老店</div>
                            </div>
                            <div style="width: auto;height: 16px;font-size: 14px;color: #999999;float: left;margin: 5px 0 0 5px;">好评率<span style="color: #FF9800">98%</span></div>
                            <div style="float: left;margin:5px 0 0 5px; ">
                                <svg t="1681700453847" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3290" width="15" height="15"><path d="M511.913993 63.989249C317.882076 63.989249 159.973123 221.898203 159.973123 415.930119c0 187.323366 315.473879 519.998656 328.890979 534.103813 6.020494 6.364522 14.449185 9.976818 23.221905 9.976818 0.172014 0 0.516042 0 0.688056 0 8.944734 0 17.545439-4.128339 23.393919-11.008903l109.22896-125.054258c145.179909-177.690576 218.629934-314.957836 218.629934-407.845456C864.026877 221.898203 706.117924 63.989249 511.913993 63.989249zM511.913993 575.903242c-88.415253 0-159.973123-71.55787-159.973123-159.973123s71.55787-159.973123 159.973123-159.973123 159.973123 71.55787 159.973123 159.973123S600.329246 575.903242 511.913993 575.903242z" fill="#FF9800" p-id="3291"></path></svg>
                            </div>
                            <div style="float: left;margin:5px 0 0 2px;">
                                <div style="width: auto;height: 17px;font-size: 14px;color: #666666;float: left">乌鲁木齐</div>
                            </div>
                            <div style="width: 200px;float: left"></div>
                            <div @click="caseDetailsPage(item.id)"  style="float: left;margin:18px 0 0 20px">
                                <el-button class="el-icon-menu">查看案例</el-button>
                            </div>
                            <div style="float: left;margin: 18px 0 0 14px">
                                <el-button style="width: 106px;" class="el-icon-user" type="primary">购买咨询</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    components: {

    },
    props: {
        id:{
            type:Number,
        },
        ShopCaseList:{
            type:Array,
            default: function() {
                return [];
            }
        }
    },
    watch:{
        ShopCaseList:{
            handler(newVal) {
                this.ShopCase = newVal;
            },
        }
    },
    computed: {

    },
    data () {
        return {
            myshop:{},
            ShopCase:{},

            TypeList:[],
            type:1
        }
    },
    mounted:function(){
        this.getMyShop();
        this.getTypeList();
        this.getCaseList(1)

    },
    methods:{
        getMyShop(){
            const that = this;
            that.newApi.getMyShop({}).then((ret)=>{
                that.myshop = ret.data;
                that.getShopCase();
            }).catch((err)=>{
                console.log(err)
            })
        },
        getShopCase(){
            const that = this;
            that.newApi.getShopCase({
                pageSize:8,
                shopId:that.myshop.id
            }).then((ret)=>{
                that.ShopCase = ret.data.slice(0,8);
            }).catch((err)=>{
                console.log(err)
            })
        },
        caseDetailsPage(id){
            window.open("/shop/caseDetailsPage/" + id);
        },
        getTypeList(){
            var that = this;
            that.newApi.getTypeList({
                lv: 1,
            }).then((res)=>{
                that.TypeList = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 分类筛选
        shopscreen (typeId){
            this.type = typeId;
            this.getCaseList(typeId)
        },
        getCaseList(typeId){
            var that = this;
            that.newApi.getCaseList({
                typeId:typeId,
                page:1,
                pageSize:20,
            }).then((res)=>{
                that.ShopCase = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },

    }
}

</script>
<style scoped>
*{
    font-size: 12px;
    font-family: myfont;
}
#root,#root2,#root3{
    width: calc(100% - 20px);;
    height:80px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    position: relative;
    padding-right: 20px;
    background-color: #ffffff;
}

.list{
    position: absolute;
    left:0;
    top:0;
    /*width: 100%;*/ /*不能为100%，不然宽度只有父容器的宽度，我掉进这个坑了。*/
    transition: all 1s;
    height:100%;
    line-height: 2.5;
    font-family: SourceHanSansSC;

}
.on{
    color:#ffffff;
    font-weight: bold;
    border-radius: 10px;
    background-color: #FF9800;

}
.list li{
    display: inline-block;
    padding:0px 30px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 18px;

}

.big_title_center b{
    font-size:20px;margin-left:10px;
}
.big_title_center small{
    float:right;margin-right:10px;
}

</style>