<template>
    <div style="width: 100%;height: 100%;overflow: hidden">
        <div v-for="(item,index) in shop" :key="index">
            <img :src="item.url" class="low">
        </div>
        <div class="low" style="display: flex">
            <div style="width: 50%;float: left">
                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/banner17.jpeg" style="width: 100%">
            </div>
            <div style="width: 50%;float: left">
                <div style="background: #FFFFFF;height: 100%">
                    <Introductionlogin></Introductionlogin>
                </div>
            </div>
        </div>
        <img class="low" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/banner15.jpeg">
    </div>
</template>
<script>
import Introductionlogin from "./components/Introductionlogin";
export default {
    components: {
        Introductionlogin

    },

    computed: {

    },
    data () {
        return {
            shop:[
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop1.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop2.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop3.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop4.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop5.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop6.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop7.jpeg"},
            ]
        }
    },
    mounted:function(){

    },
    created: function(){
    
    },
    methods:{

    }
}
</script>
<style scoped>
.low {
    width: 100%;
    height: 100%;
}
</style>
