<template>
        <!--  标准服务部分-->
        <div style="width: 100%;height: 100%;background-color: #FFFFFF">
            <div style="width: 100%;height: auto;margin-top: 5px;">
                <div style="float: left;width: 200px;height: 80px;">
                    <div style="float: left;font-weight: 400;font-size: 22px;line-height: 80px;padding-left: 53px;">标准服务</div>
                    <div style="float: left;line-height: 80px;margin-top: 6px;margin-left: 5px;font-weight: bold;">
                        <svg t="1653042634751" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6547" width="22" height="22"><path d="M448 736c-8.19 0-16.38-3.12-22.62-9.38-12.5-12.5-12.5-32.75 0-45.25L594.75 512 425.38 342.62c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l180.69 180.69c18.72 18.72 18.72 49.16 0 67.88l-180.7 180.68c-6.24 6.26-14.43 9.38-22.62 9.38z m158.06-212.69h0.31-0.31z" p-id="6548" fill="#FF9800"></path><path d="M512 128c211.74 0 384 172.26 384 384S723.74 896 512 896 128 723.74 128 512s172.26-384 384-384m0-64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z" p-id="6549" fill="#FF9800"></path></svg>
                    </div>
                </div>
                <div style="float: left;width: 50%;height: 80px;background: #aa7700;">
                    <div id='root2' class="root">
                        <ul id="list2" style="display: inline-block;margin-top: 30px;margin-left: 10px;" v-for="(item,index) in TypeList" :key="index">
                            <li @click="shopscreen(item.value)" :class="{on: item.value == type}" style="float: left;padding: 0px 30px;cursor: pointer;font-size: 18px;overflow: hidden" >{{item.label}}</li>
                        </ul>
                    </div>
                </div>
                <div style="cursor: pointer;font-size: 16px;color: red;width: 100px;float: right;height: 21px;margin-top: 30px;font-weight:bold" @click="addstyle">More</div>
                <div v-if="services.length > 0" style="width: 1500px;height: auto;margin-left: 36px;clear: both">
                    <div  v-for="(services,index) in services" :key="index" style="width: 260px;height: 450px;background: #FFFFFF;border: 1px solid rgb(220, 220, 220);float: left;margin-top: 10px;margin-left: 20px;">
                        <div style="float: left;margin: 17px 0 0 17px">
                            <div style="font-size: 16px;float: left;height: 24px;color: #999999">金额：</div>
                            <div style="height: 24px;font-size: 20px;color: #FF0000;float: left">￥{{ services.minPrice }}<span v-if="services.maxPrice !== 0" style="font-size: 20px;">-{{ services.maxPrice }}</span></div>
                        </div>
                        <div style="clear: both;margin: 11px 0 0 17px;">
                            <div style="height: 16px;font-size: 16px;color: #999999;float: left;">销量：<span style="color: #FF9800;font-size: 15px;">3542</span></div>
                        </div>
                        <div style="width: 205px;height: 37px;font-size: 16px;color: #444444;margin: 10px 0 0 19px;float: left">{{services.title}}</div>
                        <div v-for="(i,k) in services.images" :key="k">
                            <img v-if="k === 0" :src="i.url" style="width: 224px;height: 150px;margin: 5px 18px;float: left;border: 1px solid rgb(220, 220, 220);border-radius: 6px 6px 0 0;object-fit: cover;" >
                            <div style="margin-left: 18px;">
                                <img v-if="k < 5" :src="i.url"  style="width: 40px;height: 36px;margin-right: 6px;float: left;border: 1px solid rgb(220, 220, 220);object-fit: cover;">
                            </div>
                        </div>
                        <div style="clear: both">
                            <div style="width: 106px;height: 34px;border-radius: 5px 5px 5px 5px;float: left;margin: 18px 0 0 17px">
                                <el-button @click="a('/shop/serviceDetails/' + services.id)" class="el-icon-menu"  style="width: 106px;" plain>查看案例</el-button>
                            </div>
                            <div style="width: 106px;height: 34px;border-radius: 5px 5px 5px 5px;float: left;margin: 18px 0 0 14px">
                                <el-button style="width: 106px;" class="el-icon-user" type="primary" @click="utils.a('/shop/serviceVerify/' + services.id)">购买咨询</el-button>
                            </div>
                        </div>
                        <div style="width: 50px;height: 50px;margin: 14px 0 0 20px;">
                            <img width="100%;" height="100%" style="border-radius: 50%;float: left;margin-top: 30px;" :src="services.shopInfo.logo.img_url">
                        </div>
                        <div style="margin: 20px 0 0 8px;width: 160px;height: 18px;font-size: 16px;color: #666666;float: left;cursor: pointer">{{services.shopInfo.name}}</div>
                        <div style="width: 18px;height: 18px;background: #0B9D35;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 12px">
                            <div style="height: 14px;font-size: 12px;color: #FFFFFF;margin: 1px 0 0 4px">个</div>
                        </div>
                        <div style="width: 58px;height: 18px;background: #FF9800;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 6px">
                            <div style="height: 14px;font-size: 12px;color: #FFFFFF;margin: 1px 0 0 4px">10年老店</div>
                        </div>
                        <div style="width: 75px;height: 16px;font-size: 14px;color:#999999;float: left;margin: 7px 0 0 8px;">好评率<span style="font-size: 14px;color: #FF9800">98%</span></div>
                    </div>
<!--                    <div style="clear: both;text-align: center;padding-top: 10px;">-->
<!--                        <el-pagination-->
<!--                            layout="prev, pager, next"-->
<!--                            :total="50">-->
<!--                        </el-pagination>-->
<!--                    </div>-->
                </div>
            </div>
            <div style="height: 30px;clear: both"></div>
        </div>
</template>
<script>

export default {
    components: {

    },
    data(){
        return{
            services:[],
            TypeList:[],
            type:1

        }
    },
    props: {
        servicesList:{
            type:Array,
            default: function() {
                return [];
            }
        },
    },
    watch:{
        servicesList:{
            handler(newVal) {
                this.services = newVal;
            },
        }
    },
    created() {

    },
    computed: {

    },
    mounted() {
        this.getAllShopServices();
        this.getTypeList()
    },
    methods:{
        // 获取所有的服务
        getAllShopServices(){
            var that= this;
            that.newApi.getAllShopServices({
                pageSize:15,
            }).then((ret)=>{
                that.services = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        addstyle(){
            window.open('/shop/moreService/');
        },
        getTypeList(){
            var that = this;
            that.newApi.getTypeList({
                lv: 1,
            }).then((res)=>{
                that.TypeList = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 分类筛选
        shopscreen (typeId){
            this.type = typeId;
            this.getServiceList(typeId)
        },
        getServiceList(typeId){
            var that = this;
            that.newApi.getServiceList({
                typeId:typeId,
                page:1,
                pageSize:20,
            }).then((res)=>{
                that.services = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
    }
}
</script>
<style scoped>
*{
    font-size: 12px;
    font-family: myfont;
}
#root,#root2,#root3{
    width: calc(100% - 20px);;
    height:80px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    position: relative;
    padding-right: 20px;
    background-color: #ffffff;
}

.list{
    position: absolute;
    left:0;
    top:0;
    /*width: 100%;*/ /*不能为100%，不然宽度只有父容器的宽度，我掉进这个坑了。*/
    transition: all 1s;
    height:100%;
    line-height: 2.5;
    font-family: SourceHanSansSC;

}
.on{
    color:#ffffff;
    font-weight: bold;
    border-radius: 10px;
    background-color: #FF9800;

}
.list li{
    display: inline-block;
    padding:0px 30px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 18px;

}

.big_title_center b{
    font-size:20px;margin-left:10px;
}
.big_title_center small{
    float:right;margin-right:10px;
}

</style>
