<template>
    <div style="width: 100%;height: 100%;overflow: hidden">
        <!--   热门分类推荐   -->
        <div style="background: #ffffff;height: 100%">
            <div style="width: 1420px;height: 230px;background: #F2F4FD;margin: 0 40px;">
                <div style="width: 120px;height: 24px;font-size: 20px;color: #444444;margin-left:34px;padding-top: 24px;">热门分类推荐</div>
                <div style="display: flex;justify-content: center">
	                <div v-for="(item,index) in list" :key="index" style="float: left;width: calc((100% - 5px) / 7);height: 100%;">
		                <div style="width: 100%;height: 90px">
			                <img :src="item.img" style="width: 50px;height: 50px;margin: 20px calc((100% - 50px) / 2)">
		                </div>
		                <div style="width: 100%;font-size: 18px;color: #444444;text-align: center">{{item.name}}
		                </div>
		                <div style="height: 30px;font-size: 16px;color: #666666;width: 100%;text-align: center">{{item.text}}</div>
	                </div>

                </div>
            </div>
            <!--   横幅     -->
            <div style="width:  1500px;height: 90px;">
                <img style="width: 100%;height: 100%;" src="../../../../assets/imgs/index/old.jpg">
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    data(){
        return{
            list:[
                {
                    name:'便捷服务',
                    'img':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/quad.png',
                    text:'建筑园林装饰方案'
                },
                {
                    name:'策划',
                    'img':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/icon.png',
                    text:'品牌/运营策划'
                },
                {
                    name:'规划',
                    'img':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/icon1.png',
                    text:'专项/旅游规划'
                },
                {
                    name:'设计',
                    'img':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/icon2.png',
                    text:'平面/建筑规划'
                },
                {
                    name:'建设',
                    'img':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/icon3.png',
                    text:'地质灾害治理'
                },
                {
                    name:'运营',
                    'img':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/icon4.png',
                    text:'景区/农业运营'
                },
                {
                    name:'管理',
                    'img':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/icon5.png',
                    text:'城市管理咨询'
                },
            ],
        }
    },
    props: {
        services:{
            type:Array,
            default(){
                return
            }
        },
        shopGoods:{
            type:Array,
            default(){
                return
            }
        },
        ShopCase:{
            type:Array,
            default(){
                return
            }
        }
    },
    watch(){

    },
    created() {

    },
    compute: {

    },
    mounted() {

    },
    methods:{

    }
}
</script>
<style scoped>
#root,#root2,#root3{
    width: calc(100% - 20px);;
    height:80px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    position: relative;
    padding-right: 20px;
    background-color: #ffffff;
}
.on{
    color:#ffffff;
    font-weight: bold;
    border-radius: 10px;
    background-color: #FF9800;

}
.list{
    position: absolute;
    left:0;
    top:0;
    /*width: 100%;*/ /*不能为100%，不然宽度只有父容器的宽度，我掉进这个坑了。*/
    transition: all 1s;
    height:100%;
    line-height: 2.5;

}
</style>
