<template>
    <div style="width: 100%;background-color: #FFFFFF;">
        <div  style="width: 100%;height: auto;">
            <div style="padding-top: 10px;margin-left: 22px;width: 100%; height: 250px;text-align: center;">
                <div style="width: 578px;height: 262px;float: left">
                    <img src="../../../../assets/imgs/index/income.png" style="object-fit: cover;">
                </div>
                <div style="float: left;margin: 0 0 0 16px;">
                    <div style="width: 268px;height: 124px;">
                        <img src="../../../../assets/imgs/index/strength.png" style="object-fit: cover;">
                    </div>
                    <div style="width: 268px;height: 124px;margin-top: 10px;">
                        <img src="../../../../assets/imgs/index/service.png" style="object-fit: cover;">
                    </div>
                </div>
                <div style="width: 578px;height: 262px;float: left;border: 1px solid #DCDCDC;margin-left: 16px;">
                    <img style="width: 36px;height: 36px;float: left;margin: -6px" src="../../../../assets/imgs/index/triangle.png">
                    <div style="width: 360px;height: 28px;color: #031F88;font-size: 24px;margin: 30px 0 0 30px;float: left">百工严选多人才，重重认证保安全</div>
                    <div style="float: right;margin: 20px 40px 0 0"><img src="../../../../assets/imgs/index/ren.png" style="width: 40px;height: 40px"></div>
                    <div style="width: 526px;height: 45px;font-size: 18px;color: #031F88;float: left;margin: 30px 0 0 36px;">雇主发布招标需求，多个服务商在投标期内参与投标，雇主选择 一位中标后，由中标服务商向雇主提交需求解决方案的交易模式。</div>
                    <div style="float: left;margin: 40px 0 0 40px;">
                        <el-button type="primary">发布商业需求</el-button>
                    </div>
                    <div style="float: left;margin: 40px 0 0 250px;">
                        <el-button type="primary">服务方入驻</el-button>
                    </div>
                </div>
            </div>
            <div class="box-wrapper" style="">
                <div  v-for="(i,index) in type_list" :key="index">
                    <div class="box" v-if="i.value==470">
                        <div class="big_title"  v-if="item.value==470">
                            <div class="big_title_center">
                                <b>{{i.label}}</b>
                                <small style="font-size: 15px;">轻量级·高品质的在线服务</small>
                            </div>
                        </div>
                        <div  class="sub_types" v-if="i.value==470" >
                            <div class="sub_type2" v-for="(item2,index2) in i.children" :key="index2">
                                <div class="type4 op sc hand" v-for="(item3,index3) in item2.children" :key="index3" >
                                    <div v-if="item3.goods =='0'" @click="err('暂未有人上传该类型服务')" style="color:red;font-size: 16px;">{{item3.label}}</div>
                                    <div v-if="item3.goods !='0'" @click="set_type_id(item3.value)" style="font-size: 16px;">{{item3.label}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div  v-for="(type,ind) in type_list" :key="ind">
                    <div  class="box" v-if="type.value!=470" :style="type.value === 1?'':'margin-top:5px;'">
                        <div class="big_title"  v-if="type.value!=470">
                            <div class="big_title_center">
                                <div v-if="type.value === 1" style="float: left;margin: 0px 0 0 36px;">
                                    <img src="../../../../assets/imgs/index/quad.png" style="width: 25px;height: 25px;">
                                </div>
                                <div v-if="type.value === 2" style="float: left;margin: 0px 0 0 36px;">
                                    <img src="../../../../assets/imgs/index/icon.png" style="width: 25px;height: 25px;">
                                </div>
                                <div v-if="type.value === 3" style="float: left;margin: 0px 0 0 36px;">
                                    <img src="../../../../assets/imgs/index/icon1.png" style="width: 25px;height: 25px;">
                                </div>
                                <div v-if="type.value === 4" style="float: left;margin: 0px 0 0 36px;">
                                    <img src="../../../../assets/imgs/index/icon2.png" style="width: 25px;height: 25px;">
                                </div>
                                <div v-if="type.value === 5" style="float: left;margin: 0px 0 0 36px;">
                                    <img src="../../../../assets/imgs/index/icon3.png" style="width: 25px;height: 25px;">
                                </div>
                                <div v-if="type.value === 6" style="float: left;margin: 0px 0 0 36px;">
                                    <img src="../../../../assets/imgs/index/icon4.png" style="width: 25px;height: 25px;">
                                </div>
                                <b style="float: left">{{type.label}}</b>
                            </div>
                        </div>
                        <div class="sub_types" v-if="type.value!=470">
                            <div style="" class="sub_type" v-for="(it,index2) in type.children" :key="index2">
                                <div style="width:auto;text-align:left;margin-left: 15px;color: #FF6900;padding-top: 5px;"  class="type2">
                                    {{it.label}}
                                </div>
                                <div class="type3s">
                                    <div class="type3 op sc hand" v-for="(k,index3) in it.children" :key="index3" >
                                        <div style="color:#000000;font-size: 16px;cursor: pointer;">{{k.label}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    data(){
        return{
            type_list:[],
        }
    },
    created() {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    mounted() {
        this.get_type_list();

    },
    methods:{
        get_type_list(){
            var that = this;
            this.newApi.getTypeList({
                lv:3
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },

    }
}
</script>
<style scoped>
*{
    font-size: 12px;
    font-family: myfont;
}

.list li{
    display: inline-block;
    padding:0px 30px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 18px;

}

/*全部服务css样式*/
.box-wrapper {
    margin:20px 10px 10px 10px;
    -moz-column-count:3;
    /* Firefox */
    -webkit-column-count:3;
    /* Safari 和 Chrome */
    column-count:3;
    -moz-column-gap:5px;
    -webkit-column-gap:5px;
    column-gap:5px;
}

.box {
    -moz-page-break-inside:avoid;
    -webkit-column-break-inside:avoid;
    break-inside:avoid;
    border:1px solid #bcbcbc;
    background:#FFF;
    font-size: 14px;
    height: auto;
    overflow: hidden;
}

.box:first-child{
    margin-top:0px;
}
.big_title {
    border-bottom:1px solid #bcbcbc;
}

.big_title_center{
    height:25px; margin:15px 0;
    line-height:25px;
}
.big_title_center b{
    font-size:20px;margin-left:10px;
}
.big_title_center small{
    float:right;margin-right:10px;
}
.sub_types{
    padding:10px 0;
    font-size: 18px;
}
.sub_type{
    overflow:auto; margin:5px 0;height: auto;
}
.sub_type2{
    overflow:auto;
    margin:5px 15px;
    height: auto;
    ont-size: 18px;
}
.type2{
    float:left; width:90px; text-align:center;margin:0 5px;font-size: 17px;
}
.type4{
    float:left; width:auto; text-align:center;margin:5px 5px 5px 5px;
}
.type3s{
    width:calc( 100% - 30px ); overflow:auto;margin-left: 16px;
}
.type3{
    float:left;margin:7px 10px 5px 0;
}

</style>