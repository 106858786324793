<template>
<!--    <div class="dh_shop_list" style="width: 100%;min-width:1440px;background: #F6F6FC;height: auto;overflow: hidden;" v-if="is_login">-->
<!--        &lt;!&ndash;        logo背景图&ndash;&gt;-->
<!--        <div style="margin-top: 110px;position: relative;width: 100%;height: 400px;color: #ffffff; background: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon14.png');">-->
<!--            <div style="width: 1440px;height: 300px;margin: 10px auto;">-->
<!--                <div style="display: flex;justify-content: center">-->
<!--                    <div style="height: 200px;width: 100%;margin: 0 6%;line-height: 270px;font-weight: bold;font-size: 40px;color: white;position: relative;text-align: center">百工宝·互联网+知识产权一站式服务平台</div>-->
<!--                </div>-->
<!--                <div style="margin-top: 50px">-->
<!--                    <div style="width: 100%;display: flex;color: white;text-align: center;" :style="myshop != null?'margin-left: 193.5px;':'margin-left: 259.5px;'">-->
<!--                        <div v-for="(label,index) in shop_type" :key="index" style="">-->
<!--                            <div style="width:90px;height: 35px;display: flex;justify-content: center;align-items:center;font-size: 16px;cursor: pointer;" @click="add_type(label.typeId)" :class="{ Fcti: label.typeId === low }">{{label.name}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div style="height: 56px;width: 1500px;display: flex;justify-content: center">-->
<!--                            <div style="height: 56px;width: 840px;position: relative;background-color:#c2c2c266;border-radius: 5px;border-top-left-radius: 0px;">-->
<!--                                <el-input v-model="like" prefix-icon="el-icon-search" type="" name="" placeholder="请输入你要查找的资料......" style="height: 40px;width: 744px;border: none;float: left;margin-top: 8px;font-size: 16px;margin-left: 12px;"></el-input>-->
<!--                                <div @click="shopinquire(low)" style="padding: 8px 6px;float: left"><el-button type="primary">查找</el-button></div>-->
<!--                            </div>-->
<!--                            <div v-if="myshop != null" style="height: 50px;margin: 10px;padding-left: 10px;" @click="enter">-->
<!--                                <el-button class="el-icon-present" type="warning" >我的店铺</el-button>-->
<!--                            </div>-->
<!--                            <div v-if="myshop != null" style="height: 50px;margin: 10px" @click="addbackstage">-->
<!--                                <el-button class="el-icon-sell" type="primary">店铺后台</el-button>-->
<!--                            </div>-->
<!--                            <div v-if="myshop == null" style="height: 50px;margin: 10px;padding-left: 10px;" @click="old">-->
<!--                                 <el-button  type="warning" >服务方入驻</el-button>-->
<!--                            </div>-->
<!--                         <div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--         </div>-->
<!--        &lt;!&ndash;            导航标签&ndash;&gt;-->
<!--        <div style="width:100%;position: absolute;height: auto;background: #F6F6FC;">-->
<!--            <div style="width: 1500px;margin: 0 calc((100% - 1500px)/2) 0 calc((100% - 1500px)/2);height: auto;">-->
<!--                <div style="text-align: center;min-height: 60px;">-->
<!--                    <div @click="checked_select(1)" :style="checked_all_bgyx == true?'background: #ffffff;border-radius:6px 6px 0 0;color: #5A6FE6;height: 60px;':'background: #5A6FE6;color: #FFFFFF;'" style="width: 500px;float: left;cursor: pointer;border-bottom: 1px solid #DFE3F9;">-->
<!--                        <p :style="checked_all_bgyx == true?'line-height:60px;':'line-height:59px;'" style="font-size: 17px;font-weight: 700;">百工优选<span style="padding-left: 10px;font-size: 14px;">甄选优质、精品的各类专业服务</span></p>-->
<!--                    </div>-->
<!--                    <div @click="checked_select(2)" :style="checked_all_equipment == true?'background: #ffffff;border-radius:6px 6px 0 0;color: #5A6FE6;height: 60px;':'background: #5A6FE6;color: #FFFFFF;'" style="width: 500px;height: 60px;float: left;cursor: pointer;border-bottom: 1px solid #DFE3F9;">-->
<!--                        <p :style="checked_all_equipment == true?'line-height:60px;':'line-height:60px;'" style="font-size: 17px;font-weight: 700;line-height:60px;">产品设备<span style="padding-left: 10px;font-weight: 700;font-size: 14px;">甄选优质、精品的各类专业服务</span></p>-->
<!--                    </div>-->
<!--                    <div @click="checked_select(3)" :style="checked_all_service == true?'background: #ffffff;border-radius:6px 6px 0 0;color: #5A6FE6;height: 60px;':'background: #5A6FE6;color: #FFFFFF;'" style="width: 500px;height: 60px;float: left;cursor: pointer;border-bottom: 1px solid #DFE3F9;">-->
<!--                        <p :style="checked_all_service == true?'line-height:60px;':'line-height:60px;'" style="font-size: 17px;font-weight: 700;">全部服务<span style="padding-left: 10px;font-size: 14px;">众多行业优秀服务方在线接单</span></p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div v-if="checked_all_bgyx == true" style="width: 100%;height: 100%;float: left">-->
<!--                &lt;!&ndash;      百工优选热门分类&ndash;&gt;-->
<!--                    <div style="width: 100%;height: auto;">-->
<!--                        <shopOptimization :services="services" :shopGoods="shopGoods" :ShopCase="ShopCase"></shopOptimization>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;  产品设备 &ndash;&gt;-->
<!--                    <div style="width: 100%;margin-top: 10px;height: auto">-->
<!--                        <convenient :shopGoodsList="shopGoods"></convenient>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;   标准服务     &ndash;&gt;-->
<!--                    <div style="width: 100%;margin-top: 10px;height: auto;">-->
<!--                        <standard :servicesList="services"></standard>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;   优质好店     &ndash;&gt;-->
<!--                    <div style="width: 100%;margin-top: 10px;height: auto;">-->
<!--                        <highQuality></highQuality>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;  精选案例  &ndash;&gt;-->
<!--                    <div style="width: 100%;margin-top: 10px;height: auto;background-color: #FFFFFF;float: left;">-->
<!--                        <winnow :ShopCaseList="ShopCase"></winnow>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;    产品设备部分中间部分       &ndash;&gt;-->
<!--                <div v-if="checked_all_equipment == true">-->
<!--                    <div class="grid-container">-->
<!--                        <div class="box" style="width:33.2%;height: auto;overflow: hidden;float: left;">-->
<!--                            <div  v-for="(item,index) in TypeList" :key="index">-->
<!--                                <div v-if="index%3===0">-->
<!--                                    <div class="big_title">-->
<!--                                        <div class="big_title_center">-->
<!--                                            <b>{{item.label}}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="sub_types" v-if="item.value!=470">-->
<!--                                        <div style="" class="sub_type" v-for="(item2,index2) in item.children" :key="index2">-->
<!--                                            <div style="width:auto;text-align:left;margin-left: 15px;color: #FF6900;padding-top: 5px;"  class="type2">-->
<!--                                                {{item2.label}}-->
<!--                                            </div>-->
<!--                                            <div style="width:calc( 100% - 40px ); overflow:auto;margin-left: 16px;">-->
<!--                                                <div class="type3 op sc hand" v-for="(item3,index3) in item2.children" :key="index3" >-->
<!--                                                    <div style="color:#000000;font-size: 16px;cursor: pointer;">{{item3.label}}</div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="box" style="width:33.2%;height: auto;overflow: hidden;float: left">-->
<!--                            <div  v-for="(item,index) in TypeList" :key="index">-->
<!--                                <div v-if="index%3===1">-->
<!--                                    <div class="big_title">-->
<!--                                        <div class="big_title_center">-->
<!--                                            <b>{{item.label}}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="sub_types" v-if="item.value!=470">-->
<!--                                        <div style="" class="sub_type" v-for="(item2,index2) in item.children" :key="index2">-->
<!--                                            <div style="width:auto;text-align:left;margin-left: 15px;color: #FF6900;padding-top: 5px;"  class="type2">-->
<!--                                                {{item2.label}}-->
<!--                                            </div>-->
<!--                                            <div style="width:calc( 100% - 40px ); overflow:auto;margin-left: 16px;">-->
<!--                                                <div class="type3 op sc hand" v-for="(item3,index3) in item2.children" :key="index3" >-->
<!--                                                    <div style="color:#000000;font-size: 16px;cursor: pointer;">{{item3.label}}</div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="box" style="width:33.2%;height: auto;overflow: hidden;float: left">-->
<!--                            <div  v-for="(item,index) in TypeList" :key="index">-->
<!--                                <div v-if="index%3===2">-->
<!--                                    <div class="big_title">-->
<!--                                        <div class="big_title_center">-->
<!--                                            <b>{{item.label}}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="sub_types" v-if="item.value!=470">-->
<!--                                        <div style="" class="sub_type" v-for="(item2,index2) in item.children" :key="index2">-->
<!--                                            <div style="width:auto;text-align:left;margin-left: 15px;color: #FF6900;padding-top: 5px;"  class="type2">-->
<!--                                                {{item2.label}}-->
<!--                                            </div>-->
<!--                                            <div style="width:calc( 100% - 40px ); overflow:auto;margin-left: 16px;">-->
<!--                                                <div class="type3 op sc hand" v-for="(item3,index3) in item2.children" :key="index3" >-->
<!--                                                    <div  style="color:#000000;font-size: 16px;cursor: pointer;">{{item3.label}}</div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash;   服务部分右边部分 &ndash;&gt;-->
<!--                <label v-if="checked_all_service == true" style="background-color: #FFFFFF;height: 100%;width: 100%;">-->
<!--                    <fullService></fullService>-->
<!--                </label>-->
<!--            </div>-->
<!--            &lt;!&ndash;    百工优选底部    &ndash;&gt;-->
<!--            <div style="float: left;width: 100%;margin-top: 20px;">-->
<!--                <bottom></bottom>-->
<!--            </div>-->

<!--        </div>-->
<!--        <el-dialog :close-on-click-modal="false" :visible.sync="authShow" :append-to-body="true" class="custom-dialog">-->
<!--            <authentication  :userType="user.userType"></authentication>-->
<!--        </el-dialog>-->
<!--    </div>-->
	<shopLandingPage></shopLandingPage>
</template>

<script>
import Bottom from "../../components/common/Bottom";
import shopOptimization from "./components/shopHomePage/shopOptimization"
import fullService from "./components/shopHomePage/fullService"
import ShopLandingPage from "@/pages/index/shopLandingPage.vue";
import convenient from "./components/shopHomePage/Hundred/convenient";
import standard from "./components/shopHomePage/Hundred/standard";
import highQuality from "./components/shopHomePage/Hundred/highQuality";
import winnow from "./components/shopHomePage/Hundred/winnow";
import authentication from "@/components/common/authentication/authentication";
export default {
    components: {
	    ShopLandingPage,
      Bottom,
      shopOptimization,
      fullService,
      convenient,
      standard,
      highQuality,
      winnow,
      authentication
    },
    data(){
        return{
            checked_all_bgyx:true,
            checked_all_equipment:false,
            checked_all_service:false,
            type_list:[],
            plate_index:1,
            value:"",
            TypeList:[],
            soucode:"",
            myshop:{},
            shop_type:[
                {name:'标准服务',typeId:'1'},
                {name:'产品设备',typeId:'2'},
                {name:'店铺',typeId:'3'},
                {name:'案例',typeId:'4'},

            ],
            low:'1',
            like:"",
        //   产品
            services:[],
            shopGoods:[],
            ShopCase:[],
            authShow:false,

        }
    },
    created() {

    },
    computed: {
        user() {
          return this.$store.state.user
        },
        is_login(){
          return this.$store.state.is_login
        },
    },
	watch: {
		// is_login(val) {
		// 	if (val){
		// 		this.get_type_list();
		// 		this.getEquipmentTypeList();
		// 		// 查询我的店铺
		// 		this.getMyShop();
		// 	}
		// }
	},
    mounted() {
	    // if (this.is_login) {
        // this.get_type_list();
        // this.getEquipmentTypeList();
        // // 查询我的店铺
        // this.getMyShop();
	    // }
    },
    methods:{
        // 店铺后台跳转
        addbackstage(){
          var that = this;
          if(that.myshop.status == 1){
            that.utils.msg("店铺审核中");
            return false
          }
          if(that.myshop.status == 2){
            that.utils.msg("店铺审核未通过");
            return false
          }
          if(that.myshop.status == 4){
            that.utils.msg("店铺已封禁");
            return false
          }
          window.open("/shop/shopBackstage/");
        },
        add_type(typeId){
            this.low = typeId;
        },
        plate_move(index){
            this.plate_index = index;
        },
        plate_leave(){
            this.plate_index = 0;
        },


        checked_select(value){
            var that= this;
            if(value == 1){
                that.checked_all_bgyx = true;
                that.checked_all_equipment = false;
                that.checked_all_service = false;
            }else if(value == 2){
                // this.msg("功能开发中!");
                that.checked_all_bgyx = false;
                that.checked_all_equipment = true;
                that.checked_all_service = false;
            }else if(value == 3){
                that.checked_all_bgyx = false;
                that.checked_all_equipment = false;
                that.checked_all_service = true;
            }
        },

        get_type_list(){
            var that = this;
            this.newApi.getTypeList({
                lv:3
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        getEquipmentTypeList(){
            var that = this;
            this.newApi.getEquipmentTypeList({
                lv:3
            }).then((res)=>{
                that.TypeList = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },

        //封装导航
        set_navigation(id,id2){
            var box = document.getElementById(id); //外面的容器。
            var listBox = document.getElementById(id2); //ul列表。主要是移动它的left值
            var list = listBox.children;//所有列表元素
            var width = box.clientWidth /2; //为了判断是左滑还是右滑
            var totalWidth = 0;
            for(let i=0;i<list.length;i++){
                totalWidth = totalWidth + list[i].offsetWidth; //所有列表元素的总宽度
            }
            for(let i=0;i<list.length;i++){
                var _offset = totalWidth - box.clientWidth; //右边的偏移量

                list[i].addEventListener('click', function (e) {
                    for(let j=0;j<list.length;j++){
                        if(id != "root3"){
                            list[j].className = 'off'; //移除所有元素的样式
                        }else{
                            list[j].className = 'off3'; //移除所有元素的样式
                        }
                    }
                    if(id != "root3"){
                        list[i].className = 'on';  //给点击的元素添加样式
                    }else{
                        list[i].className = 'on3';  //给点击的元素添加样式
                    }

                    var offset =totalWidth - (Math.abs(listBox.offsetLeft) + box.clientWidth) + 100; //右边的偏移量 = 所有元素宽度之和 - （ul容器的左偏移量 + 父容器的宽度）

                    if(e.pageX > width && offset > 0){ //点击右侧并且右侧的偏移量大于0，左滑。
                        console.log(123);
                        listBox.style.left = (listBox.offsetLeft-200) + 'px';
                    }else if(e.pageX > width && offset > 200){ //临界位置，，右侧滚动到末尾
                        console.log(456);
                        listBox.style.left = -_offset + 'px';
                    }
                    if(e.pageX < width && listBox.offsetLeft <= -200) { //点击左侧并且左侧的偏移量小于0，左滑。
                        listBox.style.left = (listBox.offsetLeft + 200) + 'px';

                    }else if(e.pageX < width && listBox.offsetLeft < 0){ //临界位置，左侧滚到开始的位置
                        listBox.style.left = 0
                    }

                });
            }
        },
        // 服务商点击事件
        old(){
            var that = this;
          if (!this.utils.toAuth(1)) {
            return false
          }
                if (that.user.userType == 3){
                     that.utils.err("子账号不能创建店铺")
                     return
                }else {
                    that.utils.a("/shop/serVice/")
                }
        },
        enter(){
            var that = this;
            if(that.myshop.status == 1){
                that.utils.msg("店铺审核中");
                return false
            }
            if(that.myshop.status == 2){
                that.utils.msg("店铺审核未通过");
                return false
            }
            if(that.myshop.status == 4){
                that.utils.msg("店铺已封禁");
                return false
            }
            this.$router.push("/shop/shopCode/")
        },
        getMyShop(){
            var that= this;
            that.newApi.getMyShop({}).then((ret)=>{
                that.myshop = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        shopinquire(low){
            var that= this;
            // 搜索服务
            if (that.low == 1 ){
                that.newApi.getServiceList({
                    title:that.like,
                    page:1,
                    pageSize:20,
                }).then((ret)=>{
                    if(ret.isSuccess == 1) {
                        that.services = ret.data;
                        that.utils.sus("标准服务搜索成功")
                        that.like = ""
                        return
                    }

                }).catch((err)=>{
                    console.log(err)
                })
            }else if(that.low == 2){
                that.newApi.getGoodsList({
                    title:that.like
                }).then((ret)=>{
                    if(ret.isSuccess == 1) {
                        that.shopGoods = ret.data;
                        that.utils.sus("产品设备搜索成功")
                        that.like = ""
                        return
                    }
                    console.log(ret.data)
                }).catch((err)=>{
                    console.log(err)
                })
            }else if(that.low == 3){
                that.utils.err("暂未开放搜索优质好店功能！");
                that.like = ""
                return
            } else if (that.low == 4){
                that.newApi.getCaseList({
                    title:that.like
                }).then((ret)=>{
                    if (ret.isSuccess == 1) {
                        that.ShopCase = ret.data;
                        that.utils.sus("精选案例搜索成功")
                        that.like = ""
                        return
                    }
                    console.log(ret.data)
                }).catch((err)=>{
                    console.log(err)
                })
            }

        }

    }
}
</script>

<style scoped>
.list li{
    display: inline-block;
    padding:0px 30px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 18px;

}

.box {
    -moz-page-break-inside:avoid;
    -webkit-column-break-inside:avoid;
    break-inside:avoid;
    border:1px solid #bcbcbc;
    background:#FFF;
    font-size: 14px;
    height: auto;
    overflow: hidden;
}

.box:first-child{
    margin-top:0px;
}
.big_title {
    border-bottom:1px solid #bcbcbc;
}

.big_title_center{
    height:25px; margin:15px 0;
    line-height:25px;
}
.big_title_center b{
    font-size:20px;margin-left:10px;
}
.big_title_center small{
    float:right;margin-right:10px;
}
.sub_types{
    padding:10px 0;
    font-size: 18px;
}
.sub_type{
    overflow:auto; margin:5px 0;height: auto;
}
.sub_type2{
    overflow:auto;
    margin:5px 15px;
    height: auto;
    ont-size: 18px;
}
.type2{
    float:left; width:90px; text-align:center;margin:0 5px;font-size: 17px;
}
.type3{
    float:left;margin:7px 10px 5px 0;
}
/*ico图标颜色*/
.svg-icon-path-icon  { fill: red; }
.svg-icon-path-icon2 { fill: #ffffff; }
.svg-icon-path-icon3 { fill: #C89E61; }

.dh_shop_list {
    margin-top:-40px;
}

.grid-container {
    width: 100%;
    /*display: grid;*/
    overflow: hidden;
    /*grid-template-columns: repeat(3, 1fr); !* 四列布局 *!*/
    grid-gap: 10px;

}
/deep/ .el-input__inner {
    border-radius:0px
}
.Fcti {
    width: 100%;
    background-color: #c2c2c266;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 40px;
}
</style>
