<template>
    <div style="width: 100%;height: 100%;background-color: #FFFFFF;margin-top: 10px;">
        <!--  优质好店部分-->
        <div style="width: 100%;height: auto;">
            <div style="width: 1500px;height: auto;"></div>
            <div style="width: 100%;height: auto;margin-top: 5px;">
                <div style="float: left;width: 200px;height: 80px;">
                    <div style="float: left;font-weight: 400;font-size: 22px;line-height: 80px;padding-left: 53px;">优质好店</div>
                    <div style="float: left;line-height: 80px;margin-top: 6px;margin-left: 5px;font-weight: bold;">
                        <svg t="1653042634751" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6547" width="22" height="22"><path d="M448 736c-8.19 0-16.38-3.12-22.62-9.38-12.5-12.5-12.5-32.75 0-45.25L594.75 512 425.38 342.62c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l180.69 180.69c18.72 18.72 18.72 49.16 0 67.88l-180.7 180.68c-6.24 6.26-14.43 9.38-22.62 9.38z m158.06-212.69h0.31-0.31z" p-id="6548" fill="#FF9800"></path><path d="M512 128c211.74 0 384 172.26 384 384S723.74 896 512 896 128 723.74 128 512s172.26-384 384-384m0-64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z" p-id="6549" fill="#FF9800"></path></svg>
                    </div>
                </div>
                <!--                <div style="float: left;width: 50%;height: 80px;background: #aa7700; ">-->
                <!--                    <div id='root2' class="root">-->
                <!--                        <ul id="list2" class="list" >-->
                <!--                            <li class="on">热门推荐</li>-->
                <!--                            <li>策划</li>-->
                <!--                            <li>规划</li>-->
                <!--                            <li>设计</li>-->
                <!--                            <li>建设</li>-->
                <!--                            <li>运营</li>-->
                <!--                            <li>管理</li>-->
                <!--                        </ul>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!-- 优质好店 -->
                <div style="cursor: pointer;font-size: 16px;color: red;width: 100px;float: right;height: 21px;margin-top: 30px;font-weight:bold" @click="addShop">More</div>
                <div v-if="allShop.length > 0" style="width: 1500px;height: auto;margin-left: 34px;float: left">
                        <div v-for="(highQualityShop,highQualityShopIndex) in allShop" :key="highQualityShopIndex"  style="width: 330px;height: 166px;float: left;margin-left: 23px;margin-top: 15px;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);border-radius: 5px;cursor: pointer" @click="skip_shop(highQualityShop.id)">
                            <div class="background-image" :style="{'background': highQualityShop.cover == null ? 'rgb(220, 220, 220)' : 'url(' + highQualityShop.cover.img_url + ')','width': '330px','height': '166px','background-size': 'cover','object-fit': 'cover'}">
                        <div style="width: 47px;height: 47px;margin: 14px 0 0 20px;float: left;" >
                            <img width="100%;" height="100%" style="border-radius: 50%;margin-top: 15px;border: 1px solid #D9D9D9;" :src="highQualityShop.logo.img_url">
                        </div>
                        <div style="margin: 30px 0 0 8px;width: 160px;height: 18px;font-size: 16px;float: left">{{ highQualityShop.name }}</div>
                        <div style="width: 100px;float: left"></div>
                        <div  style="width: 18px;height: 18px;background: #2970FF;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 10px;">
                            <div v-if="highQualityShop.type === 1" style="width: 18px;height: 20px;color: #FFFFFF;margin: 1px 0 0 3px;">个</div>
                            <div v-if="highQualityShop.type === 2" style="width: 18px;height: 20px;color: #FFFFFF;margin: 1px 0 0 3px;">企</div>
                        </div>
                        <div style="width: 58px;height: 18px;background: #FF9800;border-radius: 2px 2px 2px 2px;float: left;margin: 6px 0 0 6px">
                            <div style="height: 14px;font-size: 12px;color: #FFFFFF;margin: 1px 0 0 4px">10年老店</div>
                        </div>
<!--                         <label v-if="highQualityShop.city.province">-->
                                <div style="color: #999999;width: auto;height: 16px;font-size: 14px;float: left;margin: 5px 0 0 12px;">店铺所在地：<span style="color:#666666">{{ highQualityShop.city.province }}</span></div>
<!--                         </label>-->
<!--                         <label v-if="highQualityShop.city.cityName">-->
<!--                             <div style="color: #999999;width: auto;height: 16px;font-size: 14px;float: left;margin: 5px 0 0 12px;">店铺所在地：<span style="color:#666666" v-for="(label,index) in highQualityShop.city.cityName" :key="index">{{ label }}</span></div>-->
<!--                         </label>-->

                         <div style="width: 290px;height: 64px;background: #FFFFFF;border-radius: 5px 5px 5px 5px;float: left;margin: 20px 0 0 20px;">
                            <div v-for="(range,indx) in range" :key="indx" >
                                <div style="float: left;margin-left: 10px;">
                                    <img :src="range.url" style="width: 20px;height: 20px;margin: 8px 0 0 25px;">
                                    <div style="width: 56px;height: 16px;font-size: 14px;color: #C89E61;margin: 1px 0 0 10px;">{{range.name_code}}</div>
                                </div>
                                <div v-if="indx !== 2" style="border-left: 1px solid #DBC098;height: 44px;float: left;margin: 8px 0 0 20px;"></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 30px;clear: both"></div>
    </div>
</template>
<script>

export default {
    components: {

    },
    data(){
        return{
            allShop:[],
            range:[
                {'url':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/shop_icone1.png','name_code':'服务广泛'},
                {'url':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/shop_icone2.png','name_code':'超多案例'},
                {'url':'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/shop_index/shop_icone3.png','name_code':'成交率高'}
            ]

        }
    },
    created() {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    mounted() {
        this.getAllShop();
    },
    methods:{
        // 获取所有的店铺
        getAllShop(){
            var that= this;
            that.newApi.getAllShop({
                pageSize:8,
            }).then((ret)=>{
                that.allShop = ret.data.slice(0,8);
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 跳转店铺
        skip_shop(id){
            this.$router.push({path:'/shop/shopCode',query:{id:id}})
        },
        addShop(){
            window.open('/shop/moreShop/');
        },
    }
}
</script>
<style scoped>
*{
    font-size: 12px;
    font-family: myfont;
}
#root,#root2,#root3{
    width: calc(100% - 20px);;
    height:80px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    position: relative;
    padding-right: 20px;
    background-color: #ffffff;
}

.list{
    position: absolute;
    left:0;
    top:0;
    /*width: 100%;*/ /*不能为100%，不然宽度只有父容器的宽度，我掉进这个坑了。*/
    transition: all 1s;
    height:100%;
    line-height: 2.5;
    font-family: SourceHanSansSC;

}
.on{
    color:#ffffff;
    font-weight: bold;
    border-radius: 10px;
    background-color: #FF9800;

}
.list li{
    display: inline-block;
    padding:0px 30px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 18px;

}

.big_title_center b{
    font-size:20px;margin-left:10px;
}
.big_title_center small{
    float:right;margin-right:10px;
}
.name {
    width: 100%;
    height: 100%;
    background: url("https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/shop/nan3.png");
    background-size: cover;
    background-position: center;
    border-radius:10px 10px 0 0;
}
.boxType{
    border-radius: 4px;

}
.background-image {
    width: 330px;
    height: 166px;
    background-size: cover;
    border-radius: 5px;
}
</style>
